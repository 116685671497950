import React from 'react';

const Main = () => {
  return (
    <div id="main-h1">
      <h1>Coming Soon...</h1>
      <br />
      <h1>Multiplayer 1v1s</h1>
    </div>
  );
};

export default Main;
